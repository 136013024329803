import { BarChartOutlined, ShareAltOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Radio,
  Result,
  Row,
  Space,
} from "antd";
import { StatusCodes } from "http-status-codes";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppLoader from "../../../components/appLoader";
import { answerData } from "../../../interfaces/responses/iPollListAnswerIdResponse";
import PollServices from "../../../services/getPollApi";
import PollLinkModal from "../sharePollLink";

const EmbededAnswerPollPage = () => {
  const [pollData, setPollData] = useState<answerData>();
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const location = useLocation();
  const [embededLinkModal, setEmbededLinkModal] = useState<boolean>(false);
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [value, setValue] = useState(1);
  const sharedPoll = JSON.parse(localStorage.getItem("URL_PARAM")!);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  const getSinglePollDetails = async () => {
    setLoader(true);
    let response = await PollServices.getPollAnswersIdData(id!);
    if (response.status_code === 200) {
      if (response.data.is_private && localStorage.getItem("login")) {
        navigate(`/shared/polls/${id}`);
      }
      setPollData(response.data);
    } else setError(response.errors?.[0]?.message);

    setLoader(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    let response = await PollServices.submitPollAnswers(
      {
        poll_id: pollData?._id!,
        selected_answer: Array.isArray(values.answers)
          ? values.answers
          : [values.answers],
      },
      pollData?.is_private ?? false
    );
    if (response.status_code === StatusCodes.OK)
      setResponseMessage("You voted successfully!");
    else setResponseMessage(response.errors?.[0]?.message);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    getSinglePollDetails();
  }, []);
  useEffect(() => {
    if (sharedPoll) localStorage.removeItem("URL_PARAM");
  }, [sharedPoll]);
  return (
    <>
      {pollData && (
        <div
          className={
            !location.pathname.includes("/embed/polls/") ? "container mt-5" : ""
          }
        >
          {loader ? (
            <AppLoader />
          ) : !responseMessage ? (
            <div>
              <Card
                hoverable
                style={{ fontFamily: "arial", padding: "1.2rem" }}
              >
                <h4>{pollData?.question}</h4>
                <p style={{ fontFamily: "arial" }} className="mt-4 font-bold">
                  Make a choice :
                </p>
                <Form
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Selct atleast one answer",
                      },
                    ]}
                    className="col-md-12 mt-1"
                    name="answers"
                  >
                    {pollData?.type !== "single" ? (
                      <Checkbox.Group style={{ width: "100%" }}>
                        <Row>
                          {pollData.options.map((x: any, i: number) => {
                            return (
                              <Col md={24} key={i} style={{ margin: "10px" }}>
                                <Checkbox key={x._id} value={x._id}>
                                  {x.option}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </Checkbox.Group>
                    ) : (
                      <Radio.Group>
                        <Space direction="vertical">
                          {pollData.options.map((x: any, index: number) => {
                            return (
                              <Radio
                                key={index}
                                value={x._id}
                                style={{ margin: "10px" }}
                              >
                                {x.option}
                              </Radio>
                            );
                          })}
                        </Space>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <div
                    className="mt-5"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ gap: "1rem", display: "flex" }}>
                      <Button
                        style={{ padding: "0 1rem" }}
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Submit
                      </Button>
                      <Button
                        style={{ padding: "0 1rem" }}
                        // type="primary"
                      >
                        Show Results
                        <BarChartOutlined
                          style={{
                            fontSize: "1.3rem",
                            marginLeft: "0.4rem",
                          }}
                        />
                      </Button>
                    </div>
                    <div>
                      <ShareAltOutlined
                        style={{ fontSize: "1.5rem", marginTop: "2rem" }}
                      />
                    </div>
                  </div>
                </Form>
                <div className="d-flex">
                  <div></div>
                </div>
              </Card>
              {pollData && (
                <PollLinkModal
                  setIsModalOpen={setEmbededLinkModal}
                  isModalOpen={embededLinkModal}
                  status={pollData.status}
                  link={`https://app.startpolling.com/polls/${pollData.code}`}
                  linkEmbdeded={`https://app.startpolling.com/embed/polls/${pollData.code}`}
                  is_private={pollData?.is_private ? true : false}
                />
              )}
            </div>
          ) : (
            <div style={{ marginTop: "15rem" }}>
              <Result title={responseMessage} />
            </div>
          )}
        </div>
      )}

      {error && (
        <>
          <Result
            style={{ marginTop: "15rem" }}
            status="warning"
            title={error}
            extra={
              <Button
                onClick={() => navigate("/create-poll")}
                type="primary"
                key="console"
              >
                Create Poll
              </Button>
            }
          />
        </>
      )}
    </>
  );
};

export default EmbededAnswerPollPage;
