import { createContext, useState } from "react";
import { ILoggedInUserResponse } from "../../interfaces/IBaseResponse";
interface IContext {
  user: ILoggedInUserResponse | undefined;
  setUser?: React.Dispatch<React.SetStateAction<ILoggedInUserResponse>>;
}
export const MyContext = createContext<IContext>({
  user: undefined,
});
export const ContextProvider = ({ children }: any) => {
  const [user, setUser] = useState<ILoggedInUserResponse>({} as any);
  return (
    <MyContext.Provider value={{ user, setUser }}>
      {children}
    </MyContext.Provider>
  );
};
