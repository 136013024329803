import { Button, Card, Checkbox, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authLogo } from "../../../assets/images";
import "../../../assets/style/auth.css";
import { ISignup } from "../../../interfaces/request/signup";
import PollServices from "../../../services/getPollApi";
import { base_url } from "../../../utils/axios";

const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = async (values: ISignup) => {
    setLoading(true);
    let response = await PollServices.signupData(values);
    if (response.status_code === 200) {
      message.success("Signup succesfully");
      navigate("/signin");
    } else {
      message.error(response.errors[0].message);
    }
    setLoading(false);
  };

  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/my-polls");
    }
  }, []);
  return (
    <>
      <div className="landingimage">
        <div className="authlogo">
          <img className="mt-2" src={authLogo} />
          <div></div>
          <div></div>
        </div>
        <div className="cardStyling">
          <Card className="p-1 innercard ">
            <div style={{ padding: "1rem 0" }}>
              <div className="text-center title">
                <span className="font-bold iq ">Sign Up</span>
                <br />
                <span>Create Account to get started Now!</span>
              </div>
              <div>
                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="vertical"
                  className="row-col forminnerpadding"
                >
                  <div className="labelauth">Email</div>
                  <Form.Item
                    className="username"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Email must be email",
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      className="fieldsstyle"
                      placeholder="Enter Your Email"
                    />
                  </Form.Item>
                  <div className="labelauth">Password</div>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                      {
                        pattern:
                          /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                        message:
                          "Password should have min 8 letters, with at least a symbol, upper and lower case letters and a number",
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ borderRadius: "1rem" }}
                      placeholder="Password"
                    />
                  </Form.Item>
                  <div className="labelauth">Confirm Password</div>
                  <Form.Item
                    name="confirmpassword"
                    dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Confirm password is required",
                      },

                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords doesn't match!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      style={{ borderRadius: "1rem" }}
                      placeholder="Confirm Password"
                    />
                  </Form.Item>
                  <Checkbox className="mt-1">
                    I agree with the terms and conditions
                  </Checkbox>
                  <Form.Item className="mt-4 px-1">
                    <Button
                      className="authbutton"
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                    >
                      Sign Up
                    </Button>
                    <a href={`${base_url}auth/google`}>
                      <Button
                        className="authbutton mt-3"
                        icon={
                          <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjzC2JyZDZ_RaWf0qp11K0lcvB6b6kYNMoqtZAQ9hiPZ4cTIOB"
                            height={25}
                          />
                        }
                      >
                        <span className="mx-3 font-bold ">
                          Sign Up With Google
                        </span>
                      </Button>
                    </a>
                  </Form.Item>
                </Form>
                <p
                  style={{ fontSize: "1rem" }}
                  className="font-semibold text-muted text-center cursor-pointer"
                >
                  Already Have An Account?{" "}
                  <Link
                    style={{ textDecoration: "none" }}
                    to="/signin"
                    className="font-bold text-dark"
                  >
                    <span style={{ color: "#1785FF" }}>Sign In</span>
                  </Link>
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default SignIn;
