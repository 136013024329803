import "antd/dist/reset.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { GlobalContextProvider } from "./helpers/contextApis/globalContext";
import { ContextProvider } from "./helpers/contextApis/loggedInUser";
import { MySearchContextProvider } from "./helpers/contextApis/searchPolls";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <MySearchContextProvider>
        <ContextProvider>
          <App />
        </ContextProvider>
      </MySearchContextProvider>
    </GlobalContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
