import {
  BarChartOutlined,
  ClusterOutlined,
  GroupOutlined,
  HddOutlined,
  ShopOutlined,
} from "@ant-design/icons";
export interface IAllAreaRoutes {
  title: string;
  icon?: any;
  key: string;
  hide?: boolean;
  path: string;
  activePath?: string;
}
export const allAreaRoutes: IAllAreaRoutes[] = [
  {
    title: "Dashboard",
    icon: <BarChartOutlined />,
    key: "/dashboard",
    path: "./pages/dashboard/statistics",
  },
  {
    title: "My Polls",
    icon: <ShopOutlined />,
    key: "/my-polls",
    path: "./pages/dashboard/myPolls",
  },
  {
    title: "",
    key: "/create-poll",
    path: "./pages/dashboard/myPolls/createPoll",
    hide: true,
  },
  {
    title: "/my-polls",
    key: "/update-poll",
    path: "./pages/dashboard/myPolls/updatePoll",
    hide: true,
  },
  {
    title: "",
    key: "/answerpoll",
    path: "./pages/dashboard/answerPolls/answerPublicPoll",
    hide: true,
  },

  {
    title: "Public Polls",
    icon: <ClusterOutlined />,
    key: "/public-polls",
    path: "./pages/dashboard/publicPolls",
  },
  {
    title: "Groups",
    icon: <GroupOutlined />,
    key: "/groups",
    path: "./pages/dashboard/groups",
  },
  {
    title: "",
    key: "/group-polls",
    path: "./pages/dashboard/groups/groupPolls",
    hide: true,
  },
  {
    title: "",
    key: "/group-polls/members",
    path: "./pages/dashboard/groups/members",
    hide: true,
  },
  {
    title: "Categories",
    icon: <HddOutlined />,
    key: "/categories",
    path: "./pages/dashboard/categories",
  },
  {
    title: "",
    key: "/interested-categories",
    path: "./pages/dashboard/categories/interestedCategories",
    hide: true,
  },
  {
    title: "",
    key: "/search/:value",
    path: "./pages/dashboard/search",
    hide: true,
  },
  {
    title: "",
    key: "/profile",
    path: "./pages/dashboard/userProfile",
    hide: true,
  },
  // notPrivate
];
