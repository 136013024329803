import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import "./assets/style/main.css";
import "./assets/style/responsive.css";

import React from "react";
import ForgotPassword from "./pages/auth/forgotPassword";
import SignIn from "./pages/auth/login";
import SignUp from "./pages/auth/signup";
import Dashboard from "./pages/dashboard";
import EmbededAnswerPollPage from "./pages/dashboard/answerPolls/answerPublicEmbededPolls";
import PageNotExist from "./pages/pageNotFound";
import PrivateRoute from "./pages/privateRoute";
import { allAreaRoutes } from "./routes";

function App() {
  const dynamicLazyLoading = (path: string) => {
    const OtherComponent = React.lazy(() => import(`${path}`));
    return OtherComponent;
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PrivateRoute Component={Dashboard} />}>
          {allAreaRoutes.map((x) => (
            <Route
              path={x.key}
              key={x.key}
              element={<PrivateRoute Component={dynamicLazyLoading(x.path)} />}
            />
          ))}

          <Route path="/shared/polls/:id" element={<EmbededAnswerPollPage />} />
        </Route>

        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/embed/polls/:id" element={<EmbededAnswerPollPage />} />
        <Route path="/polls/:id" element={<EmbededAnswerPollPage />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<PageNotExist />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
