import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { GlobalContext } from "../../helpers/contextApis/globalContext";
import { MyContext } from "../../helpers/contextApis/loggedInUser";
import { INotificationsData } from "../../interfaces/responses/iNotificationsResponse";
import getPollApi from "../../services/getPollApi";

const PrivateRoute = ({ Component }: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { setUser } = useContext<any>(MyContext);
  const { values, setValues } = useContext<any>(GlobalContext);
  let loggedInUser = JSON.parse(localStorage.getItem("login")!);
  useEffect(() => {
    if (!loggedInUser) {
      navigate("/signin", { state: id });
    } else {
      setUser(loggedInUser);
    }
    const socket = io("http://localhost:3014");
    socket.emit("connectedWithUser", {
      userId: loggedInUser?._id,
      message: "Connected with user id",
    });

    socket.on("markedAsUnread", (data: INotificationsData) => {
      setValues({
        markedUnread: data,
      });
    });
    return () => {
      socket.disconnect();
    };
  }, []);
  useEffect(() => {
    if (!values?.markedUnread)
      getPollApi.getNotifications().then((res) =>
        setValues({
          markedUnread: res.data.markedUnread,
          notifications: res.data.foundNotifications,
        })
      );
  }, []);
  return <Component />;
};

export default PrivateRoute;
