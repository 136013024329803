import PollServices from "../../../services/getPollApi";
import { Button, Card, Form, Input, Layout, Typography, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Content } = Layout;

const ForgotPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const onFinish = async (values: { email: string }) => {
    // setLoading(true);
    let response = await PollServices.forgotPassword(values.email);
    if (
      response.status_code === 200 &&
      !response.data.message.includes("wait for 1 min")
    ) {
      message.success(response.data.message);
    } else if (
      response.status_code === 200 &&
      response.data.message.includes("wait for 1 min")
    ) {
      message.warning(response.data.message);
    } else message.error(response.errors?.message)
    }
  
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    let login = localStorage.getItem("login");
    if (login) {
      navigate("/home");
    }
  }, []);
  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Content className="p-0">
          <div className="sign-up-header">
            <div className="content">
              <Title></Title>
              <p className="text-lg"></p>
            </div>
          </div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Trouble logging in? </h5>}
          >
            <div style={{ textAlign: "center" }}>
              {" "}
              <ExclamationCircleOutlined
                style={{ textAlign: "center", fontSize: "4rem", color: "red" }}
              />
            </div>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row mt-4"
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter your email!",
                  },
                ]}
              >
                <Input
                  style={{ borderRadius: "1rem", height: "3rem" }}
                  placeholder="Email"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Forgot password
                </Button>
              </Form.Item>
            </Form>
            <p className="font-semibold text-muted text-center">
              {/* Go Back */}
              <Link to="/signin" className="font-bold text-dark"></Link>
            </p>
          </Card>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter your email!",
              },
            ]}
          ></Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter your email!",
              },
            ]}
          ></Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter your email!",
              },
            ]}
          ></Form.Item>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter your email!",
              },
            ]}
          ></Form.Item>
        </Content>
      </div>
    </>
  );
};

export default ForgotPassword;
