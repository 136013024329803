import { Divider, Menu } from "antd";
import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { startpolling } from "../../assets/images";
import { IAllAreaRoutes, allAreaRoutes } from "../../routes";
export interface User {
  email: string;
  is_admin: boolean;
  token: string;
}
function Sidenav({ color }: any) {
  const user: User = JSON.parse(localStorage.getItem("login")!);
  const location = useLocation();
  const [title, setTitle] = useState<string>();
  const isNavLinkActive = (match: IAllAreaRoutes) => {
    return match.activePath === location?.pathname;
  };
  return (
    <>
      <div style={{ margin: "0.7rem 1.5rem" }} className="brand">
        <img src={startpolling} style={{ height: "40px" }} />
      </div>
      <Divider />
      <Menu theme="dark" mode="inline">
        {allAreaRoutes.map((x) => {
          if (!x.hide)
            if (x.key === "/categories" && !user?.is_admin) return null;
            else
              return (
                <Menu.Item className="ant-menu-item-selected" key={x.key}>
                  <NavLink style={{ textDecoration: "none" }} to={x.key}>
                    <div>
                      <span
                        style={
                          x.title === title ? { background: "#1890ff" } : {}
                        }
                        className="icon"
                      >
                        {x.icon}
                      </span>
                      <span className="label">{x.title}</span>
                    </div>
                  </NavLink>
                </Menu.Item>
              );
        })}
      </Menu>
    </>
  );
}

export default Sidenav;
