import { Spin } from 'antd';
import "bootstrap/dist/css/bootstrap.min.css";

import '../../assets/style/apploader.css';
const AppLoader = () => {
  return (
      <div className="example mt-5">
        <Spin style={{marginTop:"300px"}} />
    </div>
  );
};

export default AppLoader;
