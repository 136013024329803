import { Button, Card, Checkbox, Col, Form, Input, Row, message } from "antd";
import { StatusCodes } from "http-status-codes";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { authLogo } from "../../../assets/images";
import "../../../assets/style/auth.css";
import { MyContext } from "../../../helpers/contextApis/loggedInUser";
import { Ilogin } from "../../../interfaces/request/login";
import PollServices from "../../../services/getPollApi";
import { base_url } from "../../../utils/axios";

const SignIn = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { setUser } = useContext<any>(MyContext);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const urlParam = queryParams.get("url");
  const accessToken = queryParams.get("accessToken");
  const sharedParam = JSON.parse(localStorage.getItem("URL_PARAM")!);
  const [form] = Form.useForm();
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const navigate = useNavigate();
  const { url } = useParams();
  const onFinish = async (values: Ilogin) => {
    setLoading(true);
    let response = await PollServices.loginData({
      email: values.email,
      password: values.password,
    });
    if (response.status_code === 200) {
      message.success("Login success");
      localStorage.setItem("login", JSON.stringify(response?.data));
      setUser(response.data);
      if (rememberMe)
        localStorage.setItem("rememberme", JSON.stringify(values));
      else localStorage.removeItem("rememberme");
      if (urlParam) navigate(`/shared${urlParam}`);
      else navigate("/dashboard");
    } else message.error(response.errors[0].message);

    setLoading(false);
  };
  const getUserDetails = async () => {
    let response = await PollServices.getUserDetailsWithToken(
      accessToken?.toString() as string
    );
    if (response.status_code === StatusCodes.OK) {
      localStorage.setItem(
        "login",
        JSON.stringify({
          ...response?.data,
          token: accessToken,
        })
      );

      if (sharedParam) navigate(`/shared${sharedParam}`);
      else navigate("/dashboard");
    }
  };
  useEffect(() => {
    if (accessToken) getUserDetails();
  }, [accessToken]);
  useEffect(() => {
    if (localStorage.getItem("login")) navigate("/dashboard");
    let loggedinuser = JSON.parse(localStorage.getItem("rememberme")!);
    if (loggedinuser) {
      form.setFieldsValue({
        email: loggedinuser.email,
        password: loggedinuser.password,
      });
      setRememberMe(true);
    }
  }, []);
  useEffect(() => {
    if (urlParam) localStorage.setItem("URL_PARAM", JSON.stringify(urlParam));
  }, [urlParam]);
  return (
    <div className="landingimage">
      <div style={{ height: "100vh" }}>
        <div className="authlogo">
          <img className="mt-2" src={authLogo} alt="Auth Logo" />
          <div></div>
          <div></div>
        </div>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            height: "70vh",
          }}
          className="auth-content mt-5"
        >
          <Card style={{ padding: "0 2rem" }} className="innercard ">
            <p className="text-center title">
              <span className="font-bold iq">Welcome Back ! {url}</span>
              <br />
              <span>We Missed you! Please enter your Details</span>
            </p>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <div className="labelauth">Email</div>
              <Form.Item
                className="username"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email must be email",
                    type: "email",
                  },
                ]}
              >
                <Input className="fieldsstyle" placeholder="Enter Your Email" />
              </Form.Item>
              <div className="labelauth">Password</div>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                  {
                    pattern:
                      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message:
                      "Password should have min 8 letters, with at least a symbol, upper and lower case letters and a number",
                  },
                ]}
              >
                <Input.Password
                  className="fieldsstyle"
                  placeholder="Enter Your Password"
                />
              </Form.Item>
              <Row className="auth-row">
                <Col xs={24} md={9}>
                  <div className="remember-me">
                    <Checkbox
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />{" "}
                    <span>Remember Me</span>
                  </div>
                </Col>
                <Col xs={24} md={6} />
                <Col className="forgot" xs={24} md={9}>
                  <b
                    style={{ cursor: "pointer", color: "#1785FF" }}
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </b>
                </Col>
              </Row>
              <Form.Item className="mt-4 px-1">
                <Button
                  className="authbutton"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Sign In
                </Button>
                <a href={`${base_url}auth/google`}>
                  <Button
                    className="authbutton mt-3"
                    icon={
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjzC2JyZDZ_RaWf0qp11K0lcvB6b6kYNMoqtZAQ9hiPZ4cTIOB"
                        height={25}
                        alt="Google Icon"
                      />
                    }
                  >
                    <span className="mx-3 font-bold">Sign In With Google</span>
                  </Button>
                </a>
              </Form.Item>
            </Form>
            <p
              style={{ fontSize: "1rem" }}
              className="font-semibold text-muted text-center cursor-pointer"
            >
              Don't Have An Account?{" "}
              <Link
                style={{ textDecoration: "none" }}
                to="/signup"
                className="font-bold text-dark"
              >
                <span style={{ color: "#1785FF" }}>Sign Up</span>
              </Link>
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
