import axios, { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
export const base_url = "https://api.startpolling.com/api/v1/";
// export const base_url =
//   "https://9dbf-2401-4900-1c6f-9e00-10f-2d5a-cbf1-bb29.ngrok-free.app/api/v1/";
// export const base_url = "http://localhost:3014/api/v1/";

const axiosInstance = axios.create({
  baseURL: base_url,
});

const requestHandler = (request: any) => {
  const currentUser: any = JSON.parse(localStorage.getItem("login")!);
  try {
    if (
      currentUser &&
      currentUser?.token !== undefined &&
      currentUser?.token !== ""
    )
      request.headers["Authorization"] = "Bearer " + currentUser?.token;
  } catch (e) {
    console.log(e, "err");
  }
  return request;
};
const responseHandler = (response: any) => {
  return response;
};
axiosInstance.interceptors.request.use(requestHandler, (error: AxiosError) => {
  Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  responseHandler,
  (error: AxiosError) => {
    if (error.response && error.response.status !== StatusCodes.UNAUTHORIZED) {
      if (error.response.data) return Promise.reject(error.response.data);
      return Promise.reject(error);
    }
    if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
      if (error.response.status === 401) {
        localStorage.removeItem("login");
        window.location.href =
          window.location.pathname.includes("/polls") &&
            !localStorage.getItem("login")
            ? `/signin?url=${window.location.pathname}`
            : "/signin";

        if (error.response.data) return Promise.reject(error.response.data);
        return Promise.reject(error);
      }
    } else if (
      error.response &&
      error.response.status === StatusCodes.INTERNAL_SERVER_ERROR
    ) {
    }
  }
);

export default axiosInstance;
