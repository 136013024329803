import { Suspense, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "../../assets/style/quilleditor.css";
import AppLoader from "../../components/appLoader";
import Main from "../../components/main";

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/") navigate("/dashboard");
  }, [location.pathname]);

  return (
    <div>
      {location.pathname === "/" ? (
        <AppLoader />
      ) : (
        <Main>
          <Suspense fallback={<AppLoader />}>
            <Outlet />
          </Suspense>
        </Main>
      )}
    </div>
  );
};

export default Dashboard;
