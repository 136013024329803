import { createContext, useState } from "react";
interface IContext {
  values: undefined;
  setValues?: React.Dispatch<React.SetStateAction<any>>;
}
export const GlobalContext = createContext<IContext>({
  values: undefined,
});
export const GlobalContextProvider = ({ children }: any) => {
  const [values, setValues] = useState<any>();
  return (
    <GlobalContext.Provider value={{ values, setValues }}>
      {children}
    </GlobalContext.Provider>
  );
};
