import { Button, Input, Modal, message } from "antd";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";

interface IpropsData {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  handleOk?: () => void;
  linkEmbdeded: string;
  link: string;
  is_private: boolean;
  status: string;
}
const PollLinkModal = (props: IpropsData) => {
  const { setIsModalOpen, handleOk, isModalOpen, link } = props;

  const [copyText, setCopyText] = useState<string>("");
  const [copyEmbeded, setCopyEmbeded] = useState<string>("");

  const copyToClipboard = () => {
    copy(copyText);
    message.success("Copied to clipboard");
  };
  const copyEmbededToClipboard = () => {
    copy(copyEmbeded);
    message.success("Copied to clipboard");
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (props.link && props.linkEmbdeded) {
      setCopyText(props.link);
      setCopyEmbeded(props.linkEmbdeded);
    }
  }, [props.link, props.linkEmbdeded]);
  return (
    <div>
      <Modal
        width={900}
        style={{ height: "10rem", padding: "10px" }}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="row">
          <div className="col-md-12">
            <h5>Share Poll Link</h5>
          </div>
          <div className="row mt-3">
            <div className="col-md-8">
              <Input type="text" value={copyText} />
            </div>
            <div className="col-md-4">
              <Button
                style={{ width: "100px" }}
                type="primary"
                onClick={copyToClipboard}
              >
                Copy
              </Button>
            </div>
          </div>
          {!props.is_private && props.status === "approved" && (
            <div className="row mt-3">
              <div className="col-md-8">
                <Input type="text" value={copyEmbeded} />
              </div>
              <div className="col-md-4">
                <Button type="primary" onClick={copyEmbededToClipboard}>
                  Copy Emebeded Code
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PollLinkModal;
