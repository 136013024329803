import { InfoCircleOutlined } from "@ant-design/icons";
import type { DrawerProps } from "antd";
import { Avatar, Card, Drawer } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../helpers/contextApis/globalContext";
import { foundNotifications } from "../../interfaces/responses/iNotificationsResponse";
import getPollApi from "../../services/getPollApi";
import AppLoader from "../appLoader";

interface INotifications {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const NotificationsNav = (props: INotifications) => {
  const { open, setOpen } = props;
  const [notifications, setNotifications] = useState<foundNotifications[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [placement] = useState<DrawerProps["placement"]>("right");
  const { values, setValues } = useContext<any>(GlobalContext);

  const getNotifications = async () => {
    setLoader(true);
    let response = await getPollApi.getNotifications();
    if (response.data) setNotifications(response.data.foundNotifications);
    setLoader(false);
  };
  const markedAsReadNotifications = async () => {
    let response = await getPollApi.markedAsReadNotifications();
    if (response)
      setValues({
        markedUnread: 0,
      });
  };
  useEffect(() => {
    getNotifications();
    markedAsReadNotifications();
  }, [open]);
  return (
    <>
      <Drawer
        placement={placement}
        closable={false}
        onClose={() => setOpen(!open)}
        open={open}
        key={placement}
        style={{ background: "#f1f2f6" }}
      >
        <div style={{ color: "#1677ff" }} className="bold">
          Notifications
        </div>

        {loader ? (
          <AppLoader />
        ) : notifications?.length <= 0 ? (
          <div>
            <div
              className=" text-center text-danger"
              style={{ fontSize: "5rem", marginTop: "8rem" }}
            >
              <InfoCircleOutlined />
            </div>
            <p className="text-center">No notifications yet</p>
          </div>
        ) : (
          <div className="mt-2">
            {notifications.map((x) => (
              <Card style={{ height: "7rem" }} className="mt-3">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div>
                    <Avatar size={55} src={x.poll_id.poll_logo} />
                  </div>
                  <div>
                    <div style={{ font: "message-box" }}>{x.title}</div>
                    <div style={{ fontSize: "0.9rem" }} className="mt-2">
                      {x.description}
                    </div>
                    <div style={{ fontSize: "0.8rem" }}>
                      {moment(x.createdAt).format("DD MMM YYYY HH:mm")}
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default NotificationsNav;
