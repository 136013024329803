import {
  BarsOutlined,
  BellOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Badge, Button, Dropdown, Input, MenuProps } from "antd";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import addicon from "../../assets/images/addIcon.svg";
import "../../assets/style/header.css";
import { GlobalContext } from "../../helpers/contextApis/globalContext";
import {
  IContext,
  MySearchContext,
} from "../../helpers/contextApis/searchPolls";
import NotificationsNav from "../notificationsNav";

const Navbar = (props: { visible: boolean; setVisible: any }) => {
  const [notificationNav, setNotificationav] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>();
  const { setValue } = useContext<IContext | any>(MySearchContext);
  const location = useLocation();
  const { values } = useContext<any>(GlobalContext);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 991px)").matches
  );

  const navigate = useNavigate();
  useEffect(() => window.scrollTo(0, 0));

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div onClick={() => navigate("/profile")}>Profile</div>,
    },
  ];
  useEffect(() => {
    if (!location.pathname.includes("/search") && searchValue)
      setSearchValue("");
    window
      .matchMedia("(max-width: 991px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <>
      <div className="navstyle px-3">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ gap: "1rem" }} className="d-flex aqua">
            <Input
              onChange={(e) => {
                setSearchValue(e.target.value);
                setValue(e?.target?.value);
              }}
              onPressEnter={(e) =>
                searchValue && navigate("/search/" + searchValue)
              }
              placeholder="Search questions"
              value={searchValue}
              className="searchbarnav"
              suffix={
                <SearchOutlined
                  onClick={() =>
                    searchValue && navigate("/search/" + searchValue)
                  }
                  style={{ color: "#1785FF", cursor: "pointer" }}
                />
              }
            />
            <Button
              onClick={() => {
                setSearchValue("");
                location.pathname.includes("/search") && navigate(-1);
              }}
              className="upperbutton"
            >
              Reset
            </Button>
          </div>
          {!matches ? (
            <div style={{ display: "flex", gap: "2rem" }}>
              <Button
                className="upperbuttons"
                icon={<img src={addicon} style={{ height: "1rem" }} />}
                type="primary"
                onClick={() => navigate("/create-poll")}
              >
                <span style={{ marginLeft: "0.6rem" }}>Create Poll</span>
              </Button>
              <Badge count={values?.markedUnread as any}>
                <BellOutlined
                  onClick={() => setNotificationav(true)}
                  style={{ fontSize: "1.3rem", cursor: "pointer" }}
                  className="mt-2"
                />
              </Badge>
              <Dropdown
                className="pointer mt-2"
                trigger={["click"]}
                menu={{ items }}
                placement="bottom"
                arrow
              >
                <Avatar icon={<UserOutlined />}></Avatar>
              </Dropdown>
            </div>
          ) : (
            <BarsOutlined
              onClick={() => props.setVisible(true)}
              style={{ fontSize: "1.5rem" }}
              className="mt-2 pointer"
            />
          )}
        </div>
        {notificationNav && (
          <NotificationsNav
            setOpen={setNotificationav}
            open={notificationNav}
          />
        )}
      </div>
    </>
  );
};

export default Navbar;
