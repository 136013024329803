import { createContext, useState } from "react";
export interface IContext {
  value: string | undefined;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
}
export const MySearchContext = createContext<IContext>({
  value: undefined,
});
export const MySearchContextProvider = ({ children }: any) => {
  const [value, setValue] = useState<string>("");
  return (
    <MySearchContext.Provider value={{ value, setValue }}>
      {children}
    </MySearchContext.Provider>
  );
};
