import { StatusCodes } from "http-status-codes";
import { ITableData } from "../interfaces/request/IPollList.Response";
import { IadminMaker } from "../interfaces/request/iadminMekerMember";
import { IDeletePollsGroups } from "../interfaces/request/igroupPolls";
import { IJoinGroupStatusData } from "../interfaces/request/ijoinGroupStatus";
import { IstatusValues } from "../interfaces/request/istatusValues";
import { IUpdateGroupsParams } from "../interfaces/request/iupdateGroupResponse";
import { Ilogin } from "../interfaces/request/login";
import { IsubmitAnswers } from "../interfaces/request/polls";
import { ISignup } from "../interfaces/request/signup";
import { IallpollsDeatilsResponse } from "../interfaces/responses/IallPollDetailsResponse";
import { IQuestionData } from "../interfaces/responses/IgetQuestionsResponse";
import { IAddCategoryResponse } from "../interfaces/responses/iAddCategoryResponse";
import { IAssociatedGroupData } from "../interfaces/responses/iAssociatedGroupResponse";
import { INotificationsResponse } from "../interfaces/responses/iNotificationsResponse";
import { IanswerID } from "../interfaces/responses/iPollListAnswerIdResponse";
import { IPollAnswers } from "../interfaces/responses/iPollListAnswersResponse";
import { IPublicQuestionsResponse } from "../interfaces/responses/iPublicQuestionsResponse";
import { ISearchQuestionResponse } from "../interfaces/responses/iSearchQuestionResponse";
import { IUpdatePollDataResponse } from "../interfaces/responses/iUpdatePollDataResponse";
import { IUserInfoResponse } from "../interfaces/responses/iUserInfoResponse";
import { IaddedMembersResponse } from "../interfaces/responses/iaddedMembersResponse";
import { IAdmintoMemberChangeResponse } from "../interfaces/responses/iadminMemberChnagesResponse";
import { IcreateGroupResponse } from "../interfaces/responses/icreategroupResponse";
import { IdelteGroupsResponse } from "../interfaces/responses/ideleteGroupsResponse";
import { IdeletePollResponse } from "../interfaces/responses/ideletePollResponse";
import { IForgotPassword } from "../interfaces/responses/iforgotPasswordResponse";
import {
  ICategoriesData,
  ICategoriesResponse,
} from "../interfaces/responses/igetCategoriesResponse";
import { IgetGroupMembersResponse } from "../interfaces/responses/igetGroupMembers";
import { IgroupPollsResponse } from "../interfaces/responses/igetGroupPollsResponse.";
import { IgroupsdataResponse } from "../interfaces/responses/igroupsdataResponse";
import { IshowResultResponse } from "../interfaces/responses/ishowResultResponse";
import { IstatusResponse } from "../interfaces/responses/istatusResponse";
import { IupdateGroupResponse } from "../interfaces/responses/iupdateGroupResponse";
import { ILoginResponse } from "../interfaces/responses/logindata.Response";
import { IsignupResponse } from "../interfaces/responses/signupResponse";
import { IgroupParams } from "../pages/dashboard/groups";
import { IgroupdeleteParams } from "../pages/dashboard/groups/members";
import { Ipagination } from "../pages/dashboard/myPolls/myPollsTable";
import { default as axiosInstance } from "../utils/axios";
import { API_CONSTANTS } from "./api_constants";
//PollServices
class PollServices {
  submitPollData = async (data: any): Promise<ITableData> => {
    return await axiosInstance
      .post(API_CONSTANTS.V1.SUBMIT_POLL_DATA, data)
      .then((res) => res.data)
      .catch((err) => err);
  };

  getPollData = async (params: Ipagination): Promise<IQuestionData> => {
    return await axiosInstance
      .get(API_CONSTANTS.V1.GET_POLL_DATA, {
        params: params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  updatePollData = async (
    values: FormData
  ): Promise<IUpdatePollDataResponse> => {
    return await axiosInstance
      .put(`/poll`, values)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSinglePollData = async (id: string) => {
    return await axiosInstance
      .get(`/poll/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deletePollData = async (id: string): Promise<IdeletePollResponse> => {
    return await axiosInstance
      .delete(`poll/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  submitPollAnswers = async (
    data: IsubmitAnswers,
    privateType: boolean
  ): Promise<IPollAnswers> => {
    return await axiosInstance
      .post(
        privateType ? "/poll/private_poll_answer" : "/poll/public_poll_answer",
        data
      )
      .then((res) => res.data)
      .catch((err) => err);
  };
  addEmbedPollAnswer = async (data: {
    poll_id: string;
    selected_answer: any;
  }) => {
    return await axiosInstance
      .post("/poll/embed_poll", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  statusPollAnswers = async (data: IstatusValues): Promise<IstatusResponse> => {
    return await axiosInstance
      .put(API_CONSTANTS.V1.POLL_STATUS, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  totalPolls = async () => {
    return await axiosInstance
      .get("/poll/all")
      .then((res) => res.data)
      .catch((err) => err);
  };
  getPollAnswersIdData = async (poll_code: any): Promise<IanswerID> => {
    return await axiosInstance
      .get(`/poll/code/${poll_code}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getEmbedAnswersData = (id: string) => {
    return axiosInstance
      .get(`/poll/public_code//${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  pollShowResultsData = async (
    id: string | string[]
  ): Promise<IshowResultResponse> => {
    return await axiosInstance
      .get(`/poll/result/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  publicQuestions = async (
    params: Ipagination
  ): Promise<IPublicQuestionsResponse> => {
    return await axiosInstance
      .get(API_CONSTANTS.V1.PUBLIC_QUESTIONS, {
        params: params,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAllPollData = async (): Promise<IallpollsDeatilsResponse> => {
    return await axiosInstance
      .get(API_CONSTANTS.V1.GET_ALLPOLLDATA)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getUserFilteredPolls = (data: {
    type?: string;
    page: number;
    limit: number;
  }): Promise<IQuestionData> => {
    return axiosInstance
      .get(
        "/poll/poll_filtered",
        data.type
          ? {
            params: {
              type: data.type.toLowerCase(),
              page: data.page,
              limit: data.limit,
            },
          }
          : {
            params: {
              page: data.page,
              limit: data.limit,
            },
          }
      )
      .then((res) => res.data)
      .catch((err) => err);
  };
  //group

  createGroup = async (
    groupData: IgroupParams
  ): Promise<IcreateGroupResponse> => {
    return await axiosInstance
      .post(API_CONSTANTS.V1.CREATE_GROUP, groupData)
      .then((res) => res.data)
      .catch((err) => err);
  };
  groupCreatedData = async (): Promise<IgroupsdataResponse> => {
    return await axiosInstance
      .get(API_CONSTANTS.V1.GET_GROUP_DATA)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateGroup = async (
    groupdata: IUpdateGroupsParams
  ): Promise<IupdateGroupResponse> => {
    return await axiosInstance
      .put("/group", {
        ...groupdata.data,
        group_id: groupdata.group_id,
        max_member: groupdata.data.max_member,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  addMembersGroup = async (data: any): Promise<IaddedMembersResponse> => {
    return await axiosInstance
      .post(API_CONSTANTS.V1.ADD_MEMBERS_GROUP, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getMemebersGroup = async (
    groupid: string
  ): Promise<IgetGroupMembersResponse> => {
    return await axiosInstance
      .get(`/group_member/list/${groupid}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteMembersGroup = async (groupdata: IgroupdeleteParams) => {
    return await axiosInstance
      .delete(`/group_member`, {
        data: groupdata,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };

  getAllPollsGroup = async (groupid: string): Promise<IgroupPollsResponse> => {
    return await axiosInstance
      .get(`/group/polls/${groupid}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getNotifications = (): Promise<INotificationsResponse> => {
    return axiosInstance
      .get("/notifications")
      .then((res) => res.data)
      .catch((err) => err);
  };
  markedAsReadNotifications = () => {
    return axiosInstance
      .get("/notifications/marked-read")
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteGroups = async (groupid: string): Promise<IdelteGroupsResponse> => {
    return await axiosInstance
      .delete(`/group/${groupid}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  checkGroupUserType = (data: { group_id: string; user_id: string }) => {
    return axiosInstance
      .get(`/group_member?group_id=${data.group_id}&user_id=${data.user_id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  leaveGroup = async (groupid: string) => {
    return await axiosInstance
      .delete(`group/leave_group/${groupid}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  joinGroupCode = async (data: any) => {
    return await axiosInstance
      .post(`/group/join_group/${data.groupcode}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteGroupPolls = async (data: IDeletePollsGroups) => {
    return await axiosInstance
      .delete("/group/poll", {
        data,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };

  joinGroupStatus = async (data: IJoinGroupStatusData) => {
    return await axiosInstance
      .put("/group/status", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAssociatedGroups = async (): Promise<IAssociatedGroupData[]> => {
    let response = await axiosInstance
      .get("/group/associated")
      .then((res) => res.data)
      .catch((err) => err);
    if (response.status_code === StatusCodes.OK) return response.data;
    else return [];
  };
  //admin

  makeAdminmember = async (
    data: IadminMaker
  ): Promise<IAdmintoMemberChangeResponse> => {
    return await axiosInstance
      .put(`/group_member`, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  //categories
  getCategories = (): Promise<ICategoriesResponse> => {
    return axiosInstance
      .get("/categorie")
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateCategory = (data: FormData) => {
    return axiosInstance
      .put("/categorie/update_categorie", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteCategorie = (id: string) => {
    return axiosInstance
      .delete("/categorie", {
        params: {
          _id: id,
        },
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  selectFavCategorie = (interest: ICategoriesData[]) => {
    return axiosInstance
      .post("/categorie/interest", { interest: interest.map((x) => x._id) })
      .then((res) => res.data)
      .catch((err) => err);
  };
  addCategorie = (data: FormData): Promise<IAddCategoryResponse> => {
    return axiosInstance
      .post("/categorie/add_categorie", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  searchPolls = async (
    data: string,
    pagination: { limit: number; pageNumber: number }
  ): Promise<ISearchQuestionResponse> => {
    return await axiosInstance
      .get(API_CONSTANTS.V1.SEARCH_POLLDATA, {
        params: {
          search: data,
          limit: pagination.limit,
          pageNumber: pagination.pageNumber,
        },
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  //login and signup
  loginData = (data: Ilogin): Promise<ILoginResponse> => {
    return axiosInstance
      .post(API_CONSTANTS.V1.LOGIN, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  signupData = (data: ISignup): Promise<IsignupResponse> => {
    return axiosInstance
      .post(API_CONSTANTS.V1.SIGNUP, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  forgotPassword = (email: string): Promise<IForgotPassword> => {
    return axiosInstance
      .post("/auth/forgot_password ", { email })
      .then((res) => res.data)
      .catch((err) => err);
  };
  getUserDetailsWithToken = (token: string) => {
    return axiosInstance
      .get(`/user/get-user-details?token=${token}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getUserDetails = (): Promise<IUserInfoResponse> => {
    return axiosInstance
      .get("/user/user-info")
      .then((res) => res.data)
      .catch((err) => err);
  };
}

export default new PollServices();
