export const API_CONSTANTS = {
  V1: {
    SIGNUP: '/auth/signup',
    LOGIN: '/auth/login',
    GET_POLL_DATA: '/poll/user',
    SUBMIT_POLL_DATA: '/poll',
    POLL_STATUS: '/poll/status',
    SUBMIT_POLL_ANSWERS: '/poll/poll_answer',
    PUBLIC_QUESTIONS: '/poll/public',
    SEARCH_POLLDATA:"/poll/search",
    CREATE_GROUP:"/group",
    GET_GROUP_DATA:"/group",
    ADD_MEMBERS_GROUP:"/group_member",
    GET_ALLPOLLDATA:"/poll/group/user"
  },
};
